<template>
    <div :class="show ? 'opened' : 'hidden'" class="sidebar-drawer">
        <div class="text-wrapper">
            <div class="help-text">Ak ste sa v prihláške zasekli alebo potrebujete pomôcť, tak volajte na:
                <a href="tel:+421918798197">0918 798 197</a>
                <br>
                alebo nas kontaktujte prostredníctvom formulára cez:</div>
                <div class="button-wrapper">
                  <a href="https://hemisfera.sk/pomoc-s-registraciou" target="_blank">
                    <button>
                        Kontaktovať
                    </button>
                  </a>
                </div>
            </div>
        <div class="sidebar-sidebar">
            <div class="divider-drawer"></div>
            <div class="close-bar" @click="show = !show">
                <img class="transition-drawer" :class="show ? 'rotated' : 'no-rotated'" src="./img/right-arrow.png" alt="">
                <p>Pomoc</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

  data() {
      return {
          show: false,
      }
  },
}
</script>

  <style>
  .sidebar-drawer {
    position: fixed;
    z-index: 999;
    top: 50vh;
    width: 300px;

    display: flex;
    justify-content: space-between;

    background: rgb(243, 240, 240);
    border-radius: 0 12px 12px 0;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.2);
    transition: transform 0.8s;
  }
  .transition-drawer {
    transition: transform 0.8s;
  }

  .rotated {
    transform: rotate(180deg);
  }

  .no-rotated {
    transform: rotate(0deg);
  }

  .opened {
    transform: translate(0%, -50%);
  }

  .hidden {
    transform: translate(-90%, -50%);
  }

  .text-wrapper {
    padding: 12px;
  }

  .text-wrapper button {
    width: fit-content;
    margin: 8px auto;
    padding: 6px 12px;

    background: #6CBD7A;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 600;
  }

  .text-wrapper button:hover {
    background: #218838;
  }

  .help-text {
    text-align: center;
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .button-wrapper a {
    text-decoration: none;
    color: white;
  }

  .close-bar {
    position: relative;
    width: 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 0 12px 12px 0;
    background: #7ECF6A;
    cursor: pointer;
  }
  .sidebar-sidebar {
    display: flex;
  }

  .close-bar > img {
    width: 28px;
    height: 28px;
  }

  .close-bar > p {
    margin: 12px 0;
    font-weight: 600;
    writing-mode: vertical-lr;
    transform: scale(-1, -1);
  }

  .divider-drawer {
    width: 2px;
    height: 100%;
    background: rgb(173, 171, 171);
  }
  </style>
